/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue'
import Router from 'vue-router'
//import { _trans } from '@/loaders/helpers-loader'

import * as admin from './admin'
import * as admin_types from './admin/types'
import * as admin_tools from './admin/tools'
import * as admin_reports from './admin/reports'
import * as admin_banking from './admin/banking'
import * as admin_property from './admin/property'
import * as admin_invoicing from './admin/invoicing'
import * as admin_processes from './admin/processes'
import * as admin_parameters from './admin/parameters'
import * as admin_certificates from './admin/certificates'
import * as admin_communications from './admin/communications'
import * as admin_property_building from './admin/property/building'
import * as _super from './super'
import * as _super_gmail from './super/gmail'
import * as no_user_tools from './no_user/tools'

Vue.use(Router)

/*Object.keys((_super: any) => {
  console.log('KEYS')
  console.log(_super)
  //return [_super.accounts, _super.groups, _super.roles, _super.users, _super.media]
})*/

interface RoutesType {
  path: string
  name?: any
  redirect?: string
  component: {} | (() => {})
  meta?: {
    label?: any
    index?: number
    keepAlive?: boolean
    requiresAdmin?: boolean
  }
  children?: RoutesType[]
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const routes: RoutesType[] = [
  {
    path: '/',
    name: 'Home',
    redirect: '/dashboard',
    //component: TheContainer,
    //component: () => import('@/vendor/coreui/containers/TheContainer.vue'),
    component: () => import('@/app/components/containers/TheContainer.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/app/views/Dashboard.vue')
      },
      {
        path: 'propertySelector',
        name: 'Property selector',
        component: () => import('@/app/views/PropertySelector.vue')
      },
      {
        path: 'super',
        meta: { label: 'Super' },
        component: {
          render: (c: any) => c('router-view')
        },
        /*children: Object.keys(_super => {
          console.log(_super)
          return [_super.accounts, _super.groups, _super.roles, _super.users, _super.media]
        })*/
        children: [
          _super.accounts,
          _super.groups,
          _super.roles,
          _super.users,
          _super.media,
          _super.menu,
          _super.menuelement,
          {
            path: 'gmail',
            meta: { label: 'Gmail' },
            component: {
              render: (c: any) => c('router-view')
            },
            children: [_super_gmail.token, _super_gmail.config, _super_gmail.emails, _super_gmail.parsed]
          }
        ]
      },
      {
        path: 'admin',
        meta: { label: 'Admin' },
        component: {
          render: (c: any) => c('router-view')
        },
        children: [
          admin.managers,
          admin.properties,
          {
            path: 'parameters',
            meta: { label: 'Parameters' },
            component: {
              render: (c: any) => c('router-view')
            },
            children: [admin_parameters.invoice_formats, admin_parameters.property_roundings, admin_parameters.property_interests, admin_parameters.property_late_payments, admin_parameters.property_close_payments, admin_parameters.property_invoice_messages, admin_parameters.property_generation_dates, admin_parameters.property_early_payment_discounts]
          },
          {
            path: 'processes',
            meta: { label: 'Processes' },
            component: {
              render: (c: any) => c('router-view')
            },
            children: [admin_processes.data_import, admin_processes.charges, admin_processes.closing, admin_processes.payments, admin_processes.invoice_notes, admin_processes.recurrent_charges, admin_processes.scheduled_charges, admin_processes.extra_fees]
          },
          {
            path: 'invoicing',
            meta: { label: 'Invoicing' },
            component: {
              render: (c: any) => c('router-view')
            },
            children: [admin_invoicing.formats, admin_invoicing.details, admin_invoicing.invoices, admin_invoicing.receipts]
          },
          {
            path: 'banking',
            meta: { label: 'Banking' },
            component: {
              render: (c: any) => c('router-view')
            },
            children: [admin_banking.income, admin_banking.expenses]
          },
          {
            path: 'reports',
            meta: { label: 'Reports' },
            component: {
              render: (c: any) => c('router-view')
            },
            children: admin_reports.reports
          },
          {
            path: 'property',
            meta: { label: 'Property' },
            component: {
              render: (c: any) => c('router-view')
            },
            children: [
              admin_property.data_import,
              admin_property.pucs,
              admin_property.shares,
              admin_property.budgets,
              admin_property.modules,
              admin_property.buildings,
              admin_property.imputations,
              admin_property.bank_accounts,
              admin_property.imputation_order,
              {
                path: 'building',
                meta: { label: 'Building' },
                component: {
                  render: (c: any) => c('router-view')
                },
                children: [admin_property_building.contacts]
              }
            ]
          },
          {
            path: 'types',
            meta: { label: 'Types' },
            component: {
              render: (c: any) => c('router-view')
            },
            children: [admin_types.property]
          },
          {
            path: 'certificates',
            meta: { label: 'Certificates' },
            component: {
              render: (c: any) => c('router-view')
            },
            children: [admin_certificates.certificates]
          },
          {
            path: 'communications',
            meta: { label: 'Communications' },
            component: {
              render: (c: any) => c('router-view')
            },
            children: [admin_communications.sending_communications]
          },
          {
            path: 'tools',
            meta: { label: 'Tools' },
            component: {
              render: (c: any) => c('router-view')
            },
            children: [admin_tools.providers, admin_tools.common_areas, admin_tools.common_area_reservations]
          }
        ]
      },
      /*{
        path: 'no-user',
        meta: { label: 'No user' },
        component: {
          render: (c: any) => c('router-view')
        },
        children: [*/
      {
        path: 'tools',
        meta: { label: 'Tools' },
        component: {
          render: (c: any) => c('router-view')
        },
        children: [no_user_tools.common_area_reservations]
      }
      //]
      //}
    ]
  },

  {
    path: '/pages',
    name: 'Pages',
    redirect: '/pages/404',
    component: {
      render: (c: any) => c('router-view')
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/vendor/coreui/views/pages/Page404.vue')
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/vendor/coreui/views/pages/Page500.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'Auth',
    redirect: '/login',
    component: {
      render: (c: any) => c('router-view')
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/app/views/auth/Login.vue')
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/vendor/coreui/views/pages/Register.vue')
      }
    ]
  },
  {
    path: '/invoices/preview',
    name: 'PreviewInvoice',
    component: () => null
  },
  {
    path: '/:catchAll(.*)', // '*',
    name: '404',
    component: () => import('@/vendor/coreui/views/pages/Page404.vue')
  }
]

/*const parseLabels = (routes: any) => {
  routes.forEach((route: RoutesType) => {
    / * tslint:disable * /
    if (route.name) route.name = _trans(route.name)
    if (route.meta?.label) route.meta.label = _trans(route.meta.label)
    if (route.children) route.children = parseLabels(route.children)
  })
  return routes
}*/

const router = new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  /* @ts-ignore */
  scrollBehavior: () => ({ y: 0 }),
  routes //: parseLabels(routes)
})

export default router
