export default {
  test: {}
}

export const PREFIX = '-ap-'
export const DATEFORMAT = 'YYYY-MM-DD'

export const DEBUG = process.env.VUE_APP_DEBUG == 'true'
export const API_URL = process.env.VUE_APP_API_URL
export const API_TIMEOUT = process.env.VUE_APP_API_TIMEOUT
export const BACKEND_URL = process.env.VUE_APP_BACKEND_URL

export const PUSHER_APP_ID = process.env.VUE_APP_PUSHER_APP_ID
export const PUSHER_APP_KEY = process.env.VUE_APP_PUSHER_APP_KEY
export const PUSHER_APP_SECRET = process.env.VUE_APP_PUSHER_APP_SECRET
export const PUSHER_APP_HOST = process.env.VUE_APP_PUSHER_APP_HOST
export const PUSHER_APP_PORT = process.env.VUE_APP_PUSHER_APP_PORT
export const PUSHER_APP_SPORT = process.env.VUE_APP_PUSHER_APP_SPORT
export const PUSHER_APP_TLS = process.env.VUE_APP_PUSHER_APP_TLS
export const PUSHER_APP_FORCE_TLS = process.env.VUE_APP_PUSHER_APP_FORCE_TLS
export const PUSHER_APP_CLUSTER = process.env.VUE_APP_PUSHER_APP_CLUSTER
