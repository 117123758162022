/* eslint-disable @typescript-eslint/no-explicit-any */
export const property = {
  path: 'property',
  meta: { label: 'Property types' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/types/property/PropertyTypes.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreatePropertyType',
      meta: { label: 'Create property type', requiresAdmin: true },
      component: () => import('@/app/views/admin/types/property/CreatePropertyType.vue')
    },
    {
      path: ':id',
      name: 'PropertyType',
      meta: { label: 'Property type details', requiresAdmin: true },
      component: () => import('@/app/views/admin/types/property/PropertyType.vue')
    },
    {
      path: ':id/edit',
      name: 'EditPropertyType',
      meta: { label: 'Edit property type', requiresAdmin: true },
      component: () => import('@/app/views/admin/types/property/EditPropertyType.vue')
    }
  ]
}
