/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-ignore
import Vuelidate from 'vuelidate'
import CoreuiVue from '@coreui/vue'
import Moment from 'moment'
import httpService from '@/config/services/_httpService'
//import httpService from '@/config/services/_httpServiceNew'

// @ts-ignore
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
// @ts-ignore
import { Settings } from 'luxon'
Settings.defaultLocale = 'es'

import { API_URL, PUSHER_APP_KEY, PUSHER_APP_HOST, PUSHER_APP_PORT, PUSHER_APP_SPORT, PUSHER_APP_TLS, PUSHER_APP_FORCE_TLS } from '@/config/config'

/*import axios from 'axios'
import localStorageService from '@/config/services/localStorageService'

axios.interceptors.response.use(
  response => {
    return response
  },
  function(error) {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      return axios.post(API_URL + '/auth/token', { refresh_token: localStorageService.getRefreshToken() }).then(res => {
        if (res.status === 201) {
          // 1) put token to LocalStorage
          localStorageService.setToken(res.data)
          // 2) Change Authorization header
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken()
          // 3) return originalRequest object with Axios.
          return axios(originalRequest)
        }
      })
    }
  }
)*/

const PUSHER_CONFIG = {
  enabledTransports: ['ws'],
  useTLS: PUSHER_APP_TLS,
  forceTLS: PUSHER_APP_FORCE_TLS,
  wsHost: PUSHER_APP_HOST,
  wsPort: PUSHER_APP_PORT,
  wssPort: PUSHER_APP_SPORT,
  disableStats: true
}

export default {
  load(vue: any) {
    vue.use(CoreuiVue)
    vue.use(Vuelidate)
    vue.component('datetime', Datetime)

    httpService.init()
    vue.prototype.$http = httpService
    // TODO: remove when ready
    vue.prototype.$apiAdress = API_URL

    vue.prototype.$moment = Moment

    const Pusher = require('pusher-js')
    vue.prototype.$pusher = new Pusher(PUSHER_APP_KEY, PUSHER_CONFIG)
  }
}
