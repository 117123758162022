/* eslint-disable @typescript-eslint/no-explicit-any */
export const formats = {
  path: 'formats',
  meta: { label: 'Formats' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/invoicing/invoices/Formats.vue'),
      meta: { requiresAdmin: true }
    }
  ]
}
