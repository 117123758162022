/* eslint-disable @typescript-eslint/no-explicit-any */
export const groups = {
  path: 'groups',
  meta: { label: 'Groups' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/super/groups/Groups.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: ':id',
      name: 'Group',
      meta: { label: 'Group details', requiresAdmin: true },
      component: () => import('@/app/views/super/groups/Group.vue')
    },
    {
      path: ':id/edit',
      name: 'EditGroup',
      meta: { label: 'Edit group', requiresAdmin: true },
      component: () => import('@/app/views/super/groups/EditGroup.vue')
    }
  ]
}
