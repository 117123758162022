/* eslint-disable @typescript-eslint/no-explicit-any */
export const common_areas = {
  path: 'common_areas',
  meta: { label: 'Common areas' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/tools/common_areas/CommonAreas.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateCommonArea',
      meta: { label: 'Create common area', requiresAdmin: true },
      component: () => import('@/app/views/admin/tools/common_areas/CommonAreaForm.vue')
    },
    {
      path: ':id',
      name: 'CommonArea',
      meta: { label: 'Common area details', requiresAdmin: true },
      component: () => import('@/app/views/admin/tools/common_areas/CommonArea.vue')
    },
    {
      path: ':id/edit',
      name: 'EditCommonArea',
      meta: { label: 'Edit common area', requiresAdmin: true },
      component: () => import('@/app/views/admin/tools/common_areas/CommonAreaForm.vue')
    }
  ]
}
