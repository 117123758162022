/* eslint-disable @typescript-eslint/no-explicit-any */
export const charges = {
  path: 'charges',
  meta: { label: 'Charges' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/processes/charges/Charges.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateCharge',
      meta: { label: 'Create charge', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/charges/ChargeForm.vue')
    },
    {
      path: ':id',
      name: 'Charge',
      meta: { label: 'Charge details', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/charges/Charge.vue')
    },
    {
      path: ':id/edit',
      name: 'EditCharge',
      meta: { label: 'Edit charge', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/charges/ChargeForm.vue')
    }
  ]
}
