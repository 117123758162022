/* eslint-disable @typescript-eslint/no-explicit-any */
export const buildings = {
  path: 'buildings',
  meta: { label: 'Buildings' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/property/buildings/Buildings.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateBuilding',
      meta: { label: 'Create building', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/buildings/BuildingForm.vue')
    },
    {
      path: ':id',
      name: 'Building',
      meta: { label: 'Building details', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/buildings/Building.vue')
    },
    {
      path: ':id/edit',
      name: 'EditBuilding',
      meta: { label: 'Edit building', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/buildings/BuildingForm.vue')
    },
    {
      path: ':id/dashboard',
      name: 'BuildingDashboard',
      meta: { label: 'Building dashboard', requiresAdmin: true, type: 'test' },
      component: () => import('@/app/views/admin/property/buildings/BuildingDashboard.vue')
    }
  ]
}
