/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
//import router from '../router'

Vue.use(Vuex)

const state = {
  alerts: [],
  config: {
    spinner: {
      class: 'sk-grid',
      items: Array(9).fill({ class: 'sk-grid-cube' })
    }
  },
  processes: [],
  topActions: {},
  sidebarShow: 'responsive',
  currentItems: { name: '', items: [] },
  sidebarMinimize: false,
  headerBuilding_id: ''
}

const mutations = {
  toggleSidebarDesktop(state: any) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state: any) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state: any, [variable, value]: [any, any]) {
    state[variable] = value
  },

  updateTopActions(state: any, actions: any) {
    state.topActions = actions
  },
  updateTopActionsStatuses(state: any, status: any) {
    const actions = state.topActions[status.action]
    if (actions?.length) actions[status.index].disabled = status.disabled
  },
  updateHeaderBuildingId(state: any, id: any) {
    state.headerBuilding_id = id
    //if (id) router.push({ name: 'BuildingDashboard', params: { id } })
  },
  pushAlert(state: any, alert: any) { // TODO: Fix create
    if (alert) {
      // TODO: configure options
      alert = (typeof alert === 'string') ? { message: alert } : alert
      if (alert.replace) {
        if (Array.isArray(alert.replace)) {
          for (const index in alert.replace) {
            alert.message = alert.message.replace(`_XXX${index}_`, alert.replace[index])
          }
        } else {
          alert.message = alert.message.replace('_XXX_', alert.replace)
        }
      }
      setTimeout(() => {
        state.alerts.shift()
      }, 8000)
      state.alerts.push({ ...alert, remove: false })
    } //else if (alert === false) state.alerts = []
  },
  addProcess(state: any, process: any) { // TODO: use function for uuid
    const timestamp = new Date().valueOf()
    process = { uuid: timestamp + '-' + Math.random().toString(36)[2], origin: '-Unknown-', ...process, timestamp }
    //process = { uuid: timestamp + '-' + btoa('' + Math.random()).substr(0, 15), origin: process, timestamp }
    state.processes.push(process)
    //console.log('Register process:', process) // TODO: remove this
    return process.uuid
  },
  removeProcess(state: any, process: any) { // TODO: use function for uuid
    if (state.processes.length) {
      let idx = 0
      const timestamp = new Date().valueOf()
      if (process && process.uuid) idx = state.processes.findIndex((item: any) => item.uuid === process.uuid)
      //if (idx >= 0) console.log(`Deregister process: ${idx} -> ` + (timestamp - state.processes[idx].timestamp), state.processes[idx]) // TODO: remove this
      state.processes.splice(idx > 0 ? idx : 0, 1)
    }
  }
}

export default new Vuex.Store({
  state,
  modules,
  mutations,
  actions: {
    setTopActions({ commit }, actions: any) {
      commit('updateTopActions', actions)
    },
    setTopActionsStatuses({ commit }, statuses: any) {
      commit('updateTopActionsStatuses', statuses)
    },
    setAlert({ commit }, alert: any) {
      commit('pushAlert', alert)
      // TODO: add a way to mark as displayed
    },
    setHeaderBuildingId({ commit }, building_id: any) {
      commit('updateHeaderBuildingId', building_id)
    },
    registerProcess({ commit }, process: any) {
      return commit('addProcess', process)
    },
    deRegisterProcess({ commit }, process: any) {
      commit('removeProcess', process)
    },
    setCurrentItems({ commit }, currentItems: any) {
      commit('set', ['currentItems', currentItems])
    }
  },
  getters: {
    getAlerts: state => state.alerts,
    getConfig: state => state.config,
    getProcesses: state => state.processes,
    isProcessing: state => !!state.processes.length,
    getTopActions: state => state.topActions,
    getCurrentItems: state => state.currentItems,
    getHeaderBuildingId: state => state.headerBuilding_id
  }
})
