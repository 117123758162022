/* eslint-disable @typescript-eslint/no-explicit-any */
export const contacts = {
  path: 'contacts',
  meta: { label: 'Contacts' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/property/building/contacts/Contacts.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateContact',
      meta: { label: 'Create contact', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/building/contacts/ContactForm.vue')
    },
    {
      path: ':id',
      name: 'Contact',
      meta: { label: 'Contact details', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/building/contacts/Contact.vue')
    },
    {
      path: ':id/edit',
      name: 'EditContact',
      meta: { label: 'Edit contact', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/building/contacts/ContactForm.vue')
    }
  ]
}
