/* eslint-disable @typescript-eslint/no-explicit-any */
export const invoice_formats = {
  path: 'invoice_formats',
  meta: { label: 'Invoice formats' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/parameters/invoice_formats/InvoiceFormats.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateInvoiceFormat',
      meta: { label: 'Create invoice format', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/invoice_formats/InvoiceFormatForm.vue')
    },
    {
      path: ':id',
      name: 'InvoiceFormat',
      meta: { label: 'Invoice format details', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/invoice_formats/InvoiceFormat.vue')
    },
    {
      path: ':id/edit',
      name: 'EditInvoiceFormat',
      meta: { label: 'Edit invoice format', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/invoice_formats/InvoiceFormatForm.vue')
    },
    {
      path: ':id/duplicate',
      name: 'DuplicateInvoiceFormat',
      meta: { label: 'Duplicate invoice format', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/invoice_formats/InvoiceFormatForm.vue')
    }
  ]
}
