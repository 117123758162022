/* eslint-disable @typescript-eslint/no-explicit-any */
export const extra_fees = {
  path: 'extra_fees',
  meta: { label: 'Extra fees' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/processes/extra_fees/ExtraFees.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateExtraFee',
      meta: { label: 'Create extra fee', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/extra_fees/ExtraFeeForm.vue')
    },
    {
      path: ':id',
      name: 'ExtraFee',
      meta: { label: 'Extra fee details', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/extra_fees/ExtraFee.vue')
    },
    {
      path: ':id/edit',
      name: 'EditExtraFee',
      meta: { label: 'Edit extra fee', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/extra_fees/ExtraFeeForm.vue')
    }
  ]
}
