/* eslint-disable @typescript-eslint/no-explicit-any */
export const menu = {
  path: 'menu',
  meta: { label: 'Menu' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/super/menu/MenuIndex.vue')
    },
    {
      path: 'create',
      name: 'CreateMenu',
      meta: { label: 'Create menu', requiresAdmin: true },
      component: () => import('@/app/views/super/menu/CreateMenu.vue')
    },
    {
      path: ':id/edit',
      name: 'EditMenu',
      meta: { label: 'Edit menu', requiresAdmin: true },
      component: () => import('@/app/views/super/menu/EditMenu.vue')
    },
    {
      path: ':id/delete',
      name: 'DeleteMenu',
      meta: { label: 'Delete menu', requiresAdmin: true },
      component: () => import('@/app/views/super/menu/DeleteMenu.vue')
    }
  ]
}
