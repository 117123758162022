/* eslint-disable @typescript-eslint/no-explicit-any */
export const accounts = {
  path: 'accounts',
  meta: { label: 'Accounts' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/super/accounts/Accounts.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: ':id',
      name: 'Account',
      meta: { label: 'Account details', requiresAdmin: true },
      component: () => import('@/app/views/super/accounts/Account.vue')
    },
    {
      path: ':id/edit',
      name: 'EditAccount',
      meta: { label: 'Edit account', requiresAdmin: true },
      component: () => import('@/app/views/super/accounts/EditAccount.vue')
    }
  ]
}
