/* eslint-disable @typescript-eslint/no-explicit-any */
export const menuelement = {
  path: 'menuelement',
  meta: { label: 'Menu' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: ':menu/menuelement',
      component: () => import('@/app/views/super/menuElements/ElementsIndex.vue')
    },
    {
      path: ':menu/menuelement/create',
      name: 'CreateMenuElement',
      meta: { label: 'Create menu element', requiresAdmin: true },
      component: () => import('@/app/views/super/menuElements/CreateMenuElement.vue')
    },
    {
      path: ':menu/menuelement/:id',
      name: 'MenuElement',
      meta: { label: 'Menu element details', requiresAdmin: true },
      component: () => import('@/app/views/super/menuElements/ShowMenuElement.vue')
    },
    {
      path: ':menu/menuelement/:id/edit',
      name: 'EditMenuElement',
      meta: { label: 'Edit menu element', requiresAdmin: true },
      component: () => import('@/app/views/super/menuElements/EditMenuElement.vue')
    },
    {
      path: ':menu/menuelement/:id/delete',
      name: 'DeleteMenuElement',
      meta: { label: 'Delete menu element', requiresAdmin: true },
      component: () => import('@/app/views/super/menuElements/DeleteMenuElement.vue')
    }
  ]
}
