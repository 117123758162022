/* eslint-disable @typescript-eslint/no-explicit-any */
export const emails = {
  path: 'emails',
  meta: { label: 'Emails' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      name: 'GmailEmail',
      meta: { label: 'Registered emails', requiresAdmin: true },
      component: () => import('@/app/views/super/gmail/emails/GMailEmails.vue')
    },
    {
      path: 'create',
      name: 'CreateGMailEmail',
      meta: { label: 'Create email', requiresAdmin: true },
      component: () => import('@/app/views/super/gmail/emails/GMailEmailForm.vue')
    },
    /*{
      path: ':id',
      name: 'GMailEmail',
      meta: { label: 'Registered email details', requiresAdmin: true },
      component: () => import('@/app/views/super/gmail/emails/GMailEmail.vue')
    },*/
    {
      path: ':id/edit',
      name: 'EditGMailEmail',
      meta: { label: 'Edit email', requiresAdmin: true },
      component: () => import('@/app/views/super/gmail/emails/GMailEmailForm.vue')
    }
  ]
}
