/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash'
import _messages from './lang/es.json'
import Moment from 'moment'

import { DEBUG } from '@/config/config'

const _no_messages = {}

// TODO: load the messages
export const _trans = (message: string) => {
  if (!message) return '.'
  // @ts-ignore
  const trans = _messages[message]
  // @ts-ignore
  _no_messages[message] = trans || (DEBUG ? `-------${message}` : '')
  //return trans || '-t-' + (message || '')
  return trans || (DEBUG ? `${message}.` : message)
}

export const _no_trans = () => {
  return _no_messages
}

const _cleanValue = (value: any, force?: boolean, type?: string) => {
  value = ('' + (value || '')).replace(type === 'alpha' ? /[0-9]/g : /\D/g, '')
  if (force) value = value.replace(/[,.-]/g, '')
  return parseFloat(('' + value).trim())
}

const _numeric = (value: any, decimal?: number, separator?: string) => ('' + parseFloat(value).toFixed(decimal || 0)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + (separator || ','))

const _dv_col = (value: any) => {
  if (!value) return ''
  const mask = '000000000000000'
  const primes = [71, 67, 59, 53, 47, 43, 41, 37, 29, 23, 19, 17, 13, 7, 3]
  value = mask.substr(0, mask.length - ('' + value).length) + value

  let calc = 0
  for (let i = 0; i < mask.length; i++) calc += parseInt(value[i]) * primes[i]
  calc = calc % 11

  return calc > 1 ? 11 - calc : calc
}

const _format = (value: any, type?: string, decimal?: number) => {
  switch ((type || '').trim()) {
    case '$':
    case 'currency':
      return '$ ' + _numeric(value, decimal)
    case '-$':
    case 'currency_signed':
      if (value < 0) return _numeric(value, decimal).replace('-', '-$')
      return '$' + _numeric(value, decimal)
    case 'id':
      return _numeric(value, 0, '.')
    case 'nit':
      value = _cleanValue(value, true)
      return _numeric(value, 0, '.') + '-' + _dv_col(value)
    case 'dv_col':
      return _dv_col(_cleanValue(value, true))
    case 'phone':
      value = '' + _cleanValue(value, true)
      return value.replace(/\D+/g, '').replace(/(\d{3})?(\d{3})(\d{4})/, '($1) $2-$3').replace('() ', '')
    default:
      return _numeric(value, decimal)
  }
}

const _date = (datetime: any, format?: string, fromFormat?: string) => {
  const date = Moment.utc(datetime, fromFormat).local()
  if (date.isValid()) {
    switch ((format || '').trim()) {
      case 'time':
        return date.format('hh:mm a')
      case 'fulltime':
        return date.format('hh:mm:ss a')
      case 'date':
        return date.format('YYYY-MM-DD')
      default:
        return date.format(format || 'YYYY-MM-DD hh:mm a')
      //return Moment.utc(value).toISOString()
    }
  }
  return ''
}

/**
 * Capitalize string (first char to Uppercase)
 */
/*const _capitalize = (string: any) => {
  return !string ? '' : string.charAt(0).toUpperCase() + string.slice(1);
}*/

/**
 * Humanize string (first char to Uppercase, remove extra chars)
 */
const _humanize = (string: any) => {
  //return !string ? '' : this._capitalize(string).replace();
  return !string
    ? ''
    : _.capitalize(
        _.trim(
          _.snakeCase(string)
            .replace(/_id$/, '')
            .replace(/[_-]/g, ' ')
        )
      )
}

export default {
  load(vue: any) {
    vue.prototype._ = _
    vue.prototype.$d = _date
    vue.prototype.$t = _trans
    vue.prototype.$f = _format
    vue.prototype.$m = Moment
    vue.prototype._date = _date
    vue.prototype._trans = _trans
    vue.prototype._format = _format
    vue.prototype._humanize = _humanize
    vue.prototype._no_trans = _no_trans
    vue.prototype.$messages = {
      set: (messages: any) => {
        vue.prototype.$messages = { ...vue.prototype.$messages, ...messages }
      }
    }

    window._ = _
    //window.$t = _trans
  }
}
