/* eslint-disable @typescript-eslint/no-explicit-any */
export const token = {
  path: 'token',
  meta: { label: 'Token' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      meta: { label: 'GMail token', requiresAdmin: true },
      component: () => import('@/app/views/super/gmail/GMailToken.vue')
    }
  ]
}
