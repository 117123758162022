/* eslint-disable @typescript-eslint/no-explicit-any */
export const pucs = {
  path: 'pucs',
  meta: { label: 'PUC' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/property/pucs/PUCs.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreatePuc',
      meta: { label: 'Create puc', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/pucs/PUCForm.vue')
    },
    {
      path: ':id',
      name: 'Puc',
      meta: { label: 'Puc details', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/pucs/PUC.vue')
    },
    {
      path: ':id/edit',
      name: 'EditPuc',
      meta: { label: 'Edit puc', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/pucs/PUCForm.vue')
    }
  ]
}
