/* eslint-disable @typescript-eslint/no-explicit-any */
import { invoice_formats } from '@/config/router/admin/parameters'
import { _trans } from '@/loaders/helpers-loader'

let buffor = [] as any
/*let dropdown2 = (data: any) => {
  console.log('test', data)
}*/

const dropdown = (data: any) => {
  const result = {
    _name: 'CSidebarNavDropdown',
    name: _trans(data['name']),
    route: data['href'],
    icon: data['icon'],
    _children: [] as any
  }
  for (const i in data['elements']) {
    if (data['elements'][i]['slug'] == 'dropdown') {
      result._children.push(dropdown(data['elements'][i]))
    } else {
      result._children.push({
        _name: data['elements'][i]['href'] === '#' ? 'CSidebarNavDivider' : 'CSidebarNavItem',
        name: _trans(data['elements'][i]['name']),
        to: data['elements'][i]['href'],
        icon: data['elements'][i]['icon'],
        exact: false
      })
    }
  }
  return result
}

//dropdown2 = dropdown

const rebuildData = (data: any, type: string) => {
  buffor = type === 'sidebar' ? [{ _name: 'CSidebarNav', _children: [] }] : []
  for (const k in data) {
    switch (data[k]['slug']) {
      case 'link':
        if (data[k]['href'].indexOf('http') !== -1) {
          buffor[0]._children.push({
            _name: data[k]['href'] === '#' ? 'CSidebarNavDivider' : 'CSidebarNavItem',
            name: _trans(data[k]['name']),
            href: data[k]['href'],
            icon: data[k]['icon'],
            target: '_blank'
          })
        } else {
          buffor[0]._children.push({
            _name: data[k]['href'] === '#' ? 'CSidebarNavDivider' : 'CSidebarNavItem',
            name: _trans(data[k]['name']),
            to: data[k]['href'],
            icon: data[k]['icon']
          })
        }
        break
      case 'title':
        buffor[0]._children.push({
          _name: 'CSidebarNavTitle',
          _children: [_trans(data[k]['name'])]
        })
        break
      case 'dropdown':
        buffor[0]._children.push(dropdown(data[k]))
        break
      case 'separator':
        buffor[0]._children.push({
          _name: 'CSidebarNavDivider'
        })
        break
    }
  }
  return buffor
}

const menuUtil = { dropdown, rebuildData }

export default menuUtil
