/* eslint-disable @typescript-eslint/no-explicit-any */
export const properties = {
  path: 'properties',
  meta: { label: 'Properties' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/properties/Properties.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'menu',
      component: () => import('@/app/views/admin/properties/PropertiesMenu.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateProperty',
      meta: { label: 'Create property', requiresAdmin: true },
      //component: () => import('@/app/views/admin/properties/CreateProperty.vue')
      component: () => import('@/app/views/admin/properties/PropertyForm.vue')
    },
    {
      path: ':id',
      name: 'Property',
      meta: { label: 'Property details', requiresAdmin: true },
      component: () => import('@/app/views/admin/properties/Property.vue')
    },
    {
      path: ':id/edit',
      name: 'EditProperty',
      meta: { label: 'Edit property', requiresAdmin: true },
      //component: () => import('@/app/views/admin/properties/EditProperty.vue')
      component: () => import('@/app/views/admin/properties/PropertyForm.vue')
    }
  ]
}
