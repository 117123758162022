/* eslint-disable @typescript-eslint/no-explicit-any */
export const closing = {
  path: '',
  meta: { label: 'Closing process' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: 'close_collecting',
      name: 'CloseCollecting',
      meta: { label: 'Close collecting', requiresAdmin: true, process: 'close_collecting' },
      component: () => import('@/app/views/admin/processes/closing/Closing.vue')
    },
    {
      path: 'close_invoicing',
      name: 'CreateInvoicing',
      meta: { label: 'Close invoicing', requiresAdmin: true, process: 'close_invoicing' },
      component: () => import('@/app/views/admin/processes/closing/Closing.vue')
    }
  ]
}
