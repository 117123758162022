import Axios from 'axios'
import moment from 'moment'
import authService from '@/config/services/authService'
import router from '@/config/router'
import { PREFIX } from '@/config/config'

const emptyUser = '{}'
const emptyAccount = '{}'
const emptyProperty = '{ "_periods": { "_status": false, "FAC": {}, "REC": {} } }'

/* eslint-disable @typescript-eslint/no-explicit-any */
const _auth = (commit: any, service: any, user?: any, auth?: any, action?: any) => {
  return new Promise((resolve, reject) => {
    if (auth) commit('authReset')
    if (auth) commit('authRequest')
    // @ts-ignore
    authService[service](user)
      .then((response: any) => {
        if (auth) commit('authExtra', response.data._extra)
        if (auth) commit('authSuccess', response.data) // { user: response.data.user })
        if (action) commit(service, response.data)
        resolve(response)
      })
      .catch((error: any) => {
        if (auth) commit('authError')
        reject(error)
      })
  })
}

export default {
  namespaced: true,

  state: {
    extra: {},
    status: '',
    user: localStorage.getItem(PREFIX + 'user-data') || emptyUser,
    account: localStorage.getItem(PREFIX + 'user-account') || emptyAccount,
    property: localStorage.getItem(PREFIX + 'user-property') || emptyProperty
  },

  actions: {
    login: ({ commit }: any, user: any) => _auth(commit, 'login', user, true), // Authenticate user
    secure: ({ commit }: any, user: any) => _auth(commit, 'secure', user), // Set user PIN
    //refresh: ({ commit }: any) => _auth(commit, 'refresh'), // Refresh user's token
    register: ({ commit }: any, user: any) => _auth(commit, 'register', user), // Register user
    setProfile: ({ commit }: any, user: any) => _auth(commit, 'setProfile', user, false, true), // Update user's profile
    setUser({ commit }: any, data: any) {
      commit('setUserData', data)
    },
    setExtra({ commit }: any, data: any) {
      commit('authExtra', data)
    },
    logout({ commit }: any, options?: any) {
      // TODO: check backend ?                                      // Logout user
      return new Promise(resolve => {
        commit('authReset')
        resolve(false)
        if (!options || !options.is_login) router.push({ path: '/login' }) // Avoid if already on login
      })
    }
  },

  mutations: {
    authRequest(state: any) {
      state.status = 'loading'
    },
    authReset(state: any) {
      // TODO: reset state ?
      state.user = emptyUser
      state.extra = {}
      state.token = ''
      state.status = ''
      state.property = emptyProperty
      localStorage.removeItem(PREFIX + 'user-data')
      localStorage.removeItem(PREFIX + 'user-token')
      localStorage.removeItem(PREFIX + 'user-account')
      localStorage.removeItem(PREFIX + 'user-property')
      localStorage.removeItem('roles') // TODO: Remove this
      delete Axios.defaults.headers.common['Authorization']
    },
    authSuccess(state: any, auth: any) {
      //if (auth.user) {
      const user = auth._extra.user
      if (user) {
        user.nouser = user._guard === 'no-users'
        state.status = 'success'
        state.user = JSON.stringify(user)
        localStorage.setItem(PREFIX + 'user-data', state.user)
        if (auth.access_token) {
          localStorage.setItem(PREFIX + 'user-token', auth.access_token)
          Axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth.access_token
        }
        localStorage.setItem('roles', user.roles) // TODO:  Remove this
      } else {
        // TODO: reset state ?
        state.user = emptyUser
        state.extra = {}
        state.token = ''
        state.status = 'unknown'
        state.property = emptyProperty
      }
      if (auth.account) {
        state.account = JSON.stringify(auth.account)
        localStorage.setItem(PREFIX + 'user-account', state.account)
      }
    },
    authError(state: any) {
      // TODO: reset state ?
      state.user = emptyUser
      state.extra = {}
      state.token = ''
      state.status = 'error'
      state.account = emptyAccount
      state.property = emptyProperty
    },
    authExtra(state: any, extra: any) {
      // TODO: extract extra data
      state.extra = extra || {}
      state.property = emptyProperty
      if (extra && extra.user) {
        if (extra.user.property && extra.user.property._periods) {
          const periods = extra.user.property._periods
          for (const period of ['_period', 'FAC', 'REC']) {
            if (period === '_period' && periods._period) periods._period_name = moment(periods._period, 'YYMM').format('MMM YYYY')
            else if (periods[period] && periods[period].month_id) periods[period]._period_name = moment(periods[period].month_id, 'YYMM').format('MMM YYYY')
          }
          extra.user.property._periods = periods
          state.property = JSON.stringify(extra.user.property)
        }
        localStorage.setItem(PREFIX + 'user-property', state.property)
      }
    },
    setProfile(state: any, data: any) {
      const user = JSON.parse(state.user)
      state.user = JSON.stringify({ ...user, ...data.user })
    },
    setUserData(state: any, data: any) {
      if (data._extra.user) {
        state.user = JSON.stringify(data._extra.user)
        localStorage.setItem(PREFIX + 'user-data', state.user)
        if (data.access_token) {
          localStorage.setItem(PREFIX + 'user-token', data.access_token)
          Axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token
        }
        localStorage.setItem('roles', data._extra.user.roles) // TODO:  Remove this
      }
    }
  },

  getters: {
    authStatus: (state: any) => state.status,
    isLoggedIn: (state: any) => !!state.token && state.token !== 'undefined',
    loggedUser: (state: any) => JSON.parse(state.user || emptyUser),
    userAccount: (state: any) => JSON.parse(state.account || emptyAccount),
    userProperty: (state: any) => JSON.parse(state.property || emptyProperty),
    userExtraData: (state: any) => state.extra
  }
}
