/* eslint-disable @typescript-eslint/no-explicit-any */
export const imputations = {
  path: 'imputations',
  meta: { label: 'Imputations' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/property/imputations/Imputations.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateImputation',
      meta: { label: 'Create imputation', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/imputations/ImputationForm.vue')
    },
    {
      path: ':id',
      name: 'Imputation',
      meta: { label: 'Imputation details', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/imputations/Imputation.vue')
    },
    {
      path: ':id/edit',
      name: 'EditImputation',
      meta: { label: 'Edit imputation', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/imputations/ImputationForm.vue')
    }
  ]
}
