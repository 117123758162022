/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios from 'axios'
import store from '@/config/store'
import { API_URL, API_TIMEOUT, PREFIX } from '@/config/config'

let errorCount = 0
let refreshing = false

interface FireRequest {
  config?: object
  options?: {
    retry?: string
    attempt: number
  }
  resolve?: any
  reject?: any
}

const successRequest = (response: any, process: any) => {
  store.dispatch('deRegisterProcess', process)
  if (response.data._menu) {
    store.dispatch('menu/setMenu', response.data._menu) // TODO: Move to extra or force to execute after the current request
  }
  if (response.data._menus) {
    store.dispatch('menu/setMenus', response.data._menus) // TODO: Move to extra or force to execute after the current request
  }
  if (response.data._extra) {
    store.dispatch('auth/setExtra', response.data._extra)
    if (response.data._extra.user) store.dispatch('auth/setUser', response.data)
  }
  errorCount = 0
}

//const _fireRequest = ({ config, options, resolve, reject }) => { return fireRequest({ config, options, resolve, reject }) }
const fireRequest = ({ config, options, resolve, reject }: FireRequest) => {
  const user = store.getters['auth/loggedUser']

  if (user && config) {
    //config.headers['X-Authentication'] = user.nouser ? 'no-user' : ''
    // @ts-ignore
    if (user._guard === 'no-users' && config.url) config.url = config.url.replace('/api/', '/api/no-user/').replace('/admin/', '/') // TODO: check that there is no /b/b/b...
  }

  // TODO: this should be a global function... add origin ?
  const process = {
    uuid: new Date().valueOf() + '-' + btoa('' + Math.random()).substr(0, 15), // Math.random().toString(36)[2], // TODO: use function for uuid
    origin: '_httpService',
    destination: { url: '-Unknown-', ...config }.url // TODO: find a way to get the caller
  }
  store.dispatch('registerProcess', process)

  new (Axios as any)(config) // TODO: use $http
    .then((response: any) => {
      successRequest(response, process)
      resolve(response)
    })
    .catch((error: any) => {
      store.dispatch('deRegisterProcess', process)
      const status = error && error.response ? error.response.status : 0
      //if (options && options.retry && options.attempt++ < 3) {
      if (options && options.attempt++ < 3) {
        if (status === 401) {
          let goto_logout = false
          const originalRequest = error.config
          const API_BASE = API_URL.replace(/\/v[0-9]*\//, '/')
          // @ts-ignore
          const is_login = config.url === API_BASE + 'login'
          if (!refreshing && user && !is_login) {
            refreshing = true
            return Axios.post(API_BASE + 'refresh', { email: user.email }) // TODO: Refresh only if user and token still remains (and not login)
              .then(response => {
                refreshing = false
                if (response.status === 201 && response.data.access_token) {
                  // TODO function ?
                  localStorage.setItem(PREFIX + 'user-token', response.data.access_token)
                  Axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token
                  originalRequest.headers.Authorization = 'Bearer ' + response.data.access_token

                  //if (user) originalRequest.headers['X-Authentication'] = user.nouser ? 'no-user' : ''

                  return Axios(originalRequest)
                    .then((response: any) => {
                      successRequest(response, process)
                      resolve(response)
                    })
                    .catch((error_retry: any) => {
                      reject(error_retry)
                    })
                } else {
                  goto_logout = true
                  reject(error)
                }
              })
              .catch(error_refresh => {
                refreshing = false
                goto_logout = true
                reject(error)
                console.log(error_refresh)
              })
          } else {
            if (!store.getters['auth/isLoggedIn']) goto_logout = true
            else {
              // TODO: await ? --- WAIT for refreshing to return and if success retry
              setTimeout(() => {
                window.location.reload()
              }, 500)
            }
          }

          if (goto_logout) store.dispatch('auth/logout', { is_login })
          //return refreshing
        } else if (status === 408 || status === 500 || status === 502 || status === 503 || status === 504) {
          if (options.retry === 'silent') {
            setTimeout(() => {
              console.log('Retrying... ' + options.attempt)
              fireRequest({ config, resolve, reject })
            }, 3000)
          } else {
            //confirm(_trans('We\'re having some issues at this moment, try again ?') + ` (${options.attempt})`)  // TODO: timeout for this --- automatic retry also
            /*confirm(`We're having some issues at this moment, try again ? (${options.attempt})`) // TODO: timeout for this --- automatic retry also
              .then((response: any) => {
                if (response) fireRequest({ config, options, resolve, reject })
                else {
                  console.log('Logged out?')
                  //store.dispatch('auth/logout')*/
            reject(error)
            /*}
              })*/
          }
        } else {
          reject(error)
        }
      } else {
        reject(error)
        errorCount++
        console.log('ERROR COUNT', errorCount)
        if (errorCount > 2) {
          if (store.getters['auth/isLoggedIn']) console.log('Logged out?')
          store.dispatch('auth/logout')
        }
      }
    })
}

const request = (url: string, payload: any, method?: string, files?: any, retry?: any, secure?: boolean, silent?: boolean, responseType?: string) => {
  const headers = {}
  method = method || 'GET'
  // TODO: validate this
  if (files) {
    if (typeof files === 'string') files = [files]

    // TODO: FormData ???
    /*const formData = new FormData()
    formData.append('_method', 'PUT')
    for (const item in payload) formData.append(item, payload[item])
    for (const file in files) formData.append('files[]', files[file])
    payload = formData
    headers = { ...headers, 'Content-Type': 'multipart/form-data' }* /

    /*payload.files = []
    for (const file of files) {
      const reader = new FileReader()
      reader.onload = e => {
        if (e && e.target) payload.files.push({ file, _data: e.target.result })
      }
      reader.readAsDataURL(file)
    }*/
    payload.files = files
  }
  //Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  //headers = { ...headers, 'Access-Control-Allow-Origin': '*' }

  // TODO: use secure prompt
  const options = { retry, silent, attempt: 0 }
  const config = { url, method, headers, timeout: API_TIMEOUT, params: {}, data: {}, responseType } // TODO: use config for timeout
  if (method === 'GET') config.params = payload
  else config.data = payload
  return new Promise((resolve, reject) => fireRequest({ config, options, resolve, reject }))
}

export default {
  init() {
    const token = localStorage.getItem(PREFIX + 'user-token')
    if (token) Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  },

  close() {
    localStorage.removeItem(PREFIX + 'user-token')
    delete Axios.defaults.headers.common['Authorization']
  },

  get(url: string, params: any) {
    return request(API_URL + url, params)
  },

  getBlob(url: string, params: any) {
    return request(API_URL + url, params, 'GET', false, false, false, false, 'blob')
  },

  post(url: string, payload: any, files: any) {
    return request(API_URL + url, payload, 'POST', files)
  },

  put(url: string, payload: any, files: any) {
    return request(API_URL + url + '/' + payload.id, payload, 'PUT', files)
  },

  delete(url: string, payload: any) {
    return request(API_URL + url + '/' + payload.id, payload, 'DELETE')
  },

  auth(method: string, url: string, payload: any) {
    url = API_URL.replace(/\/v[0-9]*\//, '/') + url
    return request(url, payload, method)
  },

  retry(method: string, url: string, payload: any, files: any, noVersion: any) {
    if (noVersion) url = API_URL.replace(/\/v[0-9]*\//, '/') + url
    return request(url, payload, method, files, true)
  },

  secure(method: string, url: string, payload: any, files: any, noVersion: any, retry: string) {
    if (noVersion) url = API_URL.replace(/\/v[0-9]*\//, '/') + url
    return request(url, payload, method, files, retry, true)
  },

  service(method: string, url: string, payload: any, silent: boolean) {
    url = API_URL.replace(/\/v[0-9]*\//, '/') + url
    return request(url, payload, method, false, false, false, silent)
  }
}
