/* eslint-disable @typescript-eslint/no-explicit-any */
export const modules = {
  path: 'modules',
  meta: { label: 'Modules' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/property/modules/Modules.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateModule',
      meta: { label: 'Create module', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/modules/ModuleForm.vue')
    },
    {
      path: ':id',
      name: 'Module',
      meta: { label: 'Module details', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/modules/Module.vue')
    },
    {
      path: ':id/edit',
      name: 'EditModule',
      meta: { label: 'Edit module', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/modules/ModuleForm.vue')
    }
  ]
}
