/* eslint-disable @typescript-eslint/no-explicit-any */
import menuUtil from '@/app/_utils/menu-utils'
import menuService from '@/config/services/menuService'

export default {
  namespaced: true,

  state: {
    menu: [],
    menus: {
      key: 0,
      top: false,
      sidebar: false
    }
  },

  mutations: {
    setUserMenu(state: any, menu: any) {
      menu.type = menu.type || 'sidebar'
      state.menu = menu.menu
      state.menus.key++
      state.menus[menu.type] = menuUtil.rebuildData(menu.menu, menu.type)
    }
  },

  actions: {
    setMenu: ({ commit }: any, menu: any, type?: string) => {
      commit('setUserMenu', { menu, type })
    },
    setMenus: ({ commit }: any, menus: any) => {
      for (const menu in menus) {
        commit('setUserMenu', { menu: menus[menu], type: menu })
      }
    },

    getMenu: ({ commit }: any, type?: string) => {
      menuService
        .get(type)
        .then((response: any) => {
          commit('setUserMenu', { menu: response.data.menu, type })
        })
        .catch(error => {
          console.error(error)
        })
    }
  },

  getters: {
    getUserMenu: (state: any) => state.menu,
    getUserMenus: (state: any) => state.menus,

    getMenuTop: (state: any) => state.menus.top,
    getMenuSidebar: (state: any) => state.menus.sidebar
  }
}
