/* eslint-disable @typescript-eslint/no-explicit-any */
export const payments = {
  path: 'payments',
  meta: { label: 'Payments' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/processes/payments/Payments.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreatePayment',
      meta: { label: 'Create payment', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/payments/PaymentForm.vue')
    },
    {
      path: ':id',
      name: 'Payment',
      meta: { label: 'Payment details', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/payments/Payment.vue')
    },
    {
      path: ':id/edit',
      name: 'EditPayment',
      meta: { label: 'Edit payment', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/payments/PaymentForm.vue')
    }
  ]
}
