/* eslint-disable @typescript-eslint/no-explicit-any */

// TODO: Make it dynamic ?
export default {
  load(vue: any) {
    vue.component('ACard', () => import('@/app/components/form/ACard.vue'))
    vue.component('AInput', () => import('@/app/components/form/AInput.vue'))
    vue.component('ATextArea', () => import('@/app/components/form/ATextArea.vue'))
    vue.component('ACheckBox', () => import('@/app/components/form/ACheckBox.vue'))
    vue.component('AInputFile', () => import('@/app/components/form/AInputFile.vue'))
    vue.component('ACitySelect', () => import('@/app/components/form/ACitySelect.vue'))
    vue.component('AInputArray', () => import('@/app/components/form/AInputArray.vue'))
    vue.component('AMultiSelect', () => import('@/app/components/form/AMultiSelect.vue'))
    vue.component('AMaskedInput', () => import('@/app/components/form/AMaskedInput.vue'))
    vue.component('AMultiSelectMaster', () => import('@/app/components/form/AMultiSelectMaster.vue'))
    vue.component('ACropper', () => import('@/app/components/media/ACropper.vue'))
    vue.component('ACRUDTable', () => import('@/app/components/crud/ACRUDTable.vue'))
    vue.component('AItemViewer', () => import('@/app/components/crud/AItemViewer.vue'))
    vue.component('ModalCRUD', () => import('@/app/components/crud/ModalCRUD.vue'))
    vue.component('ModalEmail', () => import('@/app/components/email/ModalEmail.vue'))
    vue.component('AModalConfirm', () => import('@/app/components/crud/AModalConfirm.vue'))
    vue.component('AInputCounter', () => import('@/app/components/form/AInputCounter.vue'))
    vue.component('AInputDatetime', () => import('@/app/components/form/AInputDatetime.vue'))

    // Utils
    vue.component('TiptapEditor', () => import('@/app/components/utils/TiptapEditor/TiptapEditor.vue'))
    vue.component('ExcelGenerator', () => import('@/app/components/utils/ExcelGenerator.vue'))
  }
}
