/* eslint-disable @typescript-eslint/no-explicit-any */
export const income = {
  path: 'income',
  meta: { label: 'Income' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: 'data_import',
      name: 'Data import',
      meta: { label: 'Data import', requiresAdmin: true, type: 'banking' },
      component: () => import('@/app/views/admin/banking/DataImport.vue')
    },
    {
      path: 'settled_history',
      name: 'Settled history',
      meta: { label: 'Settled history', requiresAdmin: true },
      component: () => import('@/app/views/admin/banking/SettledHistory.vue')
    },
    {
      path: 'deleted_records',
      name: 'Deleted records',
      meta: { label: 'Deleted records', requiresAdmin: true },
      component: () => import('@/app/views/admin/banking/DeletedRecords.vue')
    },
    {
      path: 'associated_references',
      name: 'Associated references',
      meta: { label: 'Associated references', requiresAdmin: true },
      component: () => import('@/app/views/admin/banking/AssociatedReferences.vue')
    }
  ]
}
