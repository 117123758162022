/* eslint-disable @typescript-eslint/no-explicit-any */
export const budgets = {
  path: 'budgets',
  meta: { label: 'Budgets' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/property/budgets/Budgets.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateBudget',
      meta: { label: 'Create budget', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/budgets/BudgetForm.vue')
    },
    {
      path: ':id',
      name: 'Budget',
      meta: { label: 'Budget details', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/budgets/Budget.vue')
    },
    {
      path: ':id/edit',
      name: 'EditBudget',
      meta: { label: 'Edit budget', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/budgets/BudgetForm.vue')
    }
  ]
}
