/* eslint-disable @typescript-eslint/no-explicit-any */
export const recurrent_charges = {
  path: 'recurrent_charges',
  meta: { label: 'Recurrent charges' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/processes/recurrent_charges/RecurrentCharges.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateRecurrentCharge',
      meta: { label: 'Create recurrent charge', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/recurrent_charges/RecurrentChargeForm.vue')
    },
    {
      path: ':id',
      name: 'RecurrentCharge',
      meta: { label: 'Recurrent charge details', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/recurrent_charges/RecurrentCharge.vue')
    },
    {
      path: ':id/edit',
      name: 'EditRecurrentCharge',
      meta: { label: 'Edit recurrent charge', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/recurrent_charges/RecurrentChargeForm.vue')
    }
  ]
}
