/* eslint-disable @typescript-eslint/no-explicit-any */
export const providers = {
  path: 'providers',
  meta: { label: 'Providers' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/tools/providers/Providers.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateProvider',
      meta: { label: 'Create common area', requiresAdmin: true },
      component: () => import('@/app/views/admin/tools/providers/ProviderForm.vue')
    },
    {
      path: ':id',
      name: 'Provider',
      meta: { label: 'Common area details', requiresAdmin: true },
      component: () => import('@/app/views/admin/tools/providers/Provider.vue')
    },
    {
      path: ':id/edit',
      name: 'EditProvider',
      meta: { label: 'Edit common area', requiresAdmin: true },
      component: () => import('@/app/views/admin/tools/providers/ProviderForm.vue')
    }
  ]
}
