/* eslint-disable @typescript-eslint/no-explicit-any */
export const property_invoice_messages = {
  path: 'property_invoice_messages',
  meta: { label: 'Property invoice messages' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/parameters/property_invoice_messages/PropertyInvoiceMessages.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreatePropertyInvoiceMessage',
      meta: { label: 'Create property invoice message', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_invoice_messages/PropertyInvoiceMessageForm.vue')
    },
    {
      path: ':id',
      name: 'PropertyInvoiceMessage',
      meta: { label: 'Property invoice message details', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_invoice_messages/PropertyInvoiceMessage.vue')
    },
    {
      path: ':id/edit',
      name: 'EditPropertyInvoiceMessage',
      meta: { label: 'Edit property invoice message', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_invoice_messages/PropertyInvoiceMessageForm.vue')
    }
  ]
}
