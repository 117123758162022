/* eslint-disable @typescript-eslint/no-explicit-any */
export const property_generation_dates = {
  path: 'property_generation_dates',
  meta: { label: 'Property generation dates' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/parameters/property_generation_dates/PropertyGenerationDates.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreatePropertyGenerationDate',
      meta: { label: 'Create property generation date', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_generation_dates/PropertyGenerationDateForm.vue')
    },
    {
      path: ':id',
      name: 'PropertyGenerationDate',
      meta: { label: 'Property generation date details', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_generation_dates/PropertyGenerationDate.vue')
    },
    {
      path: ':id/edit',
      name: 'EditPropertyGenerationDate',
      meta: { label: 'Edit property generation date', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_generation_dates/PropertyGenerationDateForm.vue')
    }
  ]
}
