/* eslint-disable @typescript-eslint/no-explicit-any */
export const scheduled_charges = {
  path: 'scheduled_charges',
  meta: { label: 'Scheduled charges' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/processes/scheduled_charges/ScheduledCharges.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateScheduledCharge',
      meta: { label: 'Create scheduled charge', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/scheduled_charges/ScheduledChargeForm.vue')
    },
    {
      path: ':id',
      name: 'ScheduledCharge',
      meta: { label: 'Scheduled charge details', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/scheduled_charges/ScheduledCharge.vue')
    },
    {
      path: ':id/edit',
      name: 'EditScheduledCharge',
      meta: { label: 'Edit scheduled charge', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/scheduled_charges/ScheduledChargeForm.vue')
    }
  ]
}
