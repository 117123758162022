/* eslint-disable @typescript-eslint/no-explicit-any */
export const property_close_payments = {
  path: 'property_close_payments',
  meta: { label: 'Property close payments' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/parameters/property_close_payments/PropertyClosePayments.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreatePropertyClosePayment',
      meta: { label: 'Create property close payment', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_close_payments/PropertyClosePaymentForm.vue')
    },
    {
      path: ':id',
      name: 'PropertyClosePayment',
      meta: { label: 'Property close payment details', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_close_payments/PropertyClosePayment.vue')
    },
    {
      path: ':id/edit',
      name: 'EditPropertyClosePayment',
      meta: { label: 'Edit property close payment', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_close_payments/PropertyClosePaymentForm.vue')
    }
  ]
}
