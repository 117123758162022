import Vue from 'vue'
import App from '@/app/App.vue'
import store from '@/config/store'
import router from '@/config/router'
import { iconsSet as icons } from '@/assets/icons'

import extraIcons from '@/loaders/extra-icons'
import extraLoader from '@/loaders/extra-loader'
import helpersLoader from '@/loaders/helpers-loader'
import componentsLoader from '@/loaders/components-loader'
import directivesLoader from '@/loaders/directives-loader'

extraIcons.load(Vue)
extraLoader.load(Vue)
helpersLoader.load(Vue)
componentsLoader.load(Vue)
directivesLoader.load(Vue)

Vue.config.performance = true

new Vue({
  el: '#app',
  // @ts-ignore
  icons,
  store,
  router,
  template: '<App/>',
  components: {
    App
  }
})
