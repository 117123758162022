/* eslint-disable @typescript-eslint/no-explicit-any */
export const property_late_payments = {
  path: 'property_late_payments',
  meta: { label: 'Property late payments' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/parameters/property_late_payments/PropertyLatePayments.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreatePropertyLatePayment',
      meta: { label: 'Create property late payment', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_late_payments/PropertyLatePaymentForm.vue')
    },
    {
      path: ':id',
      name: 'PropertyLatePayment',
      meta: { label: 'Property late payment details', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_late_payments/PropertyLatePayment.vue')
    },
    {
      path: ':id/edit',
      name: 'EditPropertyLatePayment',
      meta: { label: 'Edit property late payment', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_late_payments/PropertyLatePaymentForm.vue')
    }
  ]
}
