import camelCase from 'lodash/camelCase'

/* eslint-disable @typescript-eslint/no-explicit-any */
const modules: any = {}
const requireModules = require.context('./modules', false, /\.ts$/)

requireModules.keys().forEach(fileName => {
  const moduleName = camelCase(fileName.replace(/(\.\/|\.ts)/g, ''))
  modules[moduleName] = {
    namespaced: true,
    ...requireModules(fileName).default
  }
})

export default modules
