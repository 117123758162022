/* eslint-disable @typescript-eslint/no-explicit-any */
export const property_interests = {
  path: 'property_interests',
  meta: { label: 'Property interests' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/parameters/property_interests/PropertyInterests.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreatePropertyInterest',
      meta: { label: 'Create property interest', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_interests/PropertyInterestForm.vue')
    },
    {
      path: ':id',
      name: 'PropertyInterest',
      meta: { label: 'Property interest details', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_interests/PropertyInterest.vue')
    },
    {
      path: ':id/edit',
      name: 'EditPropertyInterest',
      meta: { label: 'Edit property interest', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_interests/PropertyInterestForm.vue')
    }
  ]
}
