/* eslint-disable @typescript-eslint/no-explicit-any */
export const property_roundings = {
  path: 'property_roundings',
  meta: { label: 'Property roundings' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/parameters/property_roundings/PropertyRoundings.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreatePropertyRounding',
      meta: { label: 'Create property rounding', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_roundings/PropertyRoundingForm.vue')
    },
    {
      path: ':id',
      name: 'PropertyRounding',
      meta: { label: 'Property rounding details', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_roundings/PropertyRounding.vue')
    },
    {
      path: ':id/edit',
      name: 'EditPropertyRounding',
      meta: { label: 'Edit property rounding', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_roundings/PropertyRoundingForm.vue')
    }
  ]
}
