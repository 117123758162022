/* eslint-disable @typescript-eslint/no-explicit-any */

// TODO: Make it dynamic ?
export default {
  load(vue: any) {
    vue.directive('toUppercase', {
      update(el: any, enabled: any) {
        if (enabled.value && el.value) el.value = el.value.toUpperCase()
      }
    })
    vue.directive('toLowercase', {
      update(el: any, enabled: any) {
        if (enabled.value && el.value) el.value = el.value.toLowerCase()
      }
    })
  }
}
