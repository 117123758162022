/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-ignore

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(faEye)

export default {
  load(vue: any) {
    /* add font awesome icon component */
    vue.component('font-awesome-icon', FontAwesomeIcon)
  }
}
