/* eslint-disable @typescript-eslint/no-explicit-any */
export const roles = {
  path: 'roles',
  meta: { label: 'Roles' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      meta: { requiresAdmin: true },
      component: () => import('@/app/views/super/roles/Roles.vue')
    },
    {
      path: 'create',
      name: 'CreateRole',
      meta: { label: 'Create role', requiresAdmin: true },
      component: () => import('@/app/views/super/roles/CreateRole.vue')
    },
    {
      path: ':id',
      name: 'Role',
      meta: { label: 'Role details', requiresAdmin: true },
      component: () => import('@/app/views/super/roles/Role.vue')
    },
    {
      path: ':id/edit',
      name: 'EditRole',
      meta: { label: 'Edit role', requiresAdmin: true },
      component: () => import('@/app/views/super/roles/EditRole.vue')
    }
  ]
}
