/* eslint-disable @typescript-eslint/no-explicit-any */
export const sending_communications = {
  path: 'sending_communications',
  meta: { label: 'Sending communications' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/communications/sending_communications/Communications.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateCommunication',
      meta: { label: 'Create communication', requiresAdmin: true },
      component: () => import('@/app/views/admin/communications/sending_communications/CommunicationForm.vue')
    },
    {
      path: ':id',
      name: 'Communication',
      meta: { label: 'Communication details', requiresAdmin: true },
      component: () => import('@/app/views/admin/communications/sending_communications/Communication.vue')
    },
    {
      path: ':id/edit',
      name: 'EditCommunication',
      meta: { label: 'Edit communication', requiresAdmin: true },
      component: () => import('@/app/views/admin/communications/sending_communications/CommunicationForm.vue')
    }
  ]
}
