/* eslint-disable @typescript-eslint/no-explicit-any */
export const users = {
  path: 'users',
  meta: { label: 'Users' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      meta: { requiresAdmin: true },
      component: () => import('@/app/views/super/users/Users.vue')
    },
    {
      path: ':id',
      name: 'User',
      meta: { label: 'User details', requiresAdmin: true },
      component: () => import('@/app/views/super/users/User.vue')
    },
    {
      path: ':id/edit',
      name: 'EditUser',
      meta: { label: 'Edit user', requiresAdmin: true },
      component: () => import('@/app/views/super/users/EditUser.vue')
    }
  ]
}
