/* eslint-disable @typescript-eslint/no-explicit-any */
export const property_early_payment_discounts = {
  path: 'property_early_payment_discounts',
  meta: { label: 'Property early payment discounts' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/parameters/property_early_payment_discounts/PropertyEarlyPaymentDiscounts.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreatePropertyEarlyPaymentDiscount',
      meta: { label: 'Create property early payment discount', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_early_payment_discounts/PropertyEarlyPaymentDiscountForm.vue')
    },
    {
      path: ':id',
      name: 'PropertyEarlyPaymentDiscount',
      meta: { label: 'Property early payment discount details', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_early_payment_discounts/PropertyEarlyPaymentDiscount.vue')
    },
    {
      path: ':id/edit',
      name: 'EditPropertyEarlyPaymentDiscount',
      meta: { label: 'Edit property early payment discount', requiresAdmin: true },
      component: () => import('@/app/views/admin/parameters/property_early_payment_discounts/PropertyEarlyPaymentDiscountForm.vue')
    }
  ]
}
