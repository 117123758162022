/* eslint-disable @typescript-eslint/no-explicit-any */
export const invoice_notes = {
  path: 'invoice_notes',
  meta: { label: 'Invoice notes' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/processes/invoice_notes/InvoiceNotes.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateInvoiceNote',
      meta: { label: 'Create invoice note', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/invoice_notes/InvoiceNoteForm.vue')
    },
    {
      path: ':id',
      name: 'InvoiceNote',
      meta: { label: 'Invoice note details', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/invoice_notes/InvoiceNote.vue')
    },
    {
      path: ':id/edit',
      name: 'EditInvoiceNote',
      meta: { label: 'Edit invoice note', requiresAdmin: true },
      component: () => import('@/app/views/admin/processes/invoice_notes/InvoiceNoteForm.vue')
    }
  ]
}
