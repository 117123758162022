import httpService from './_httpService'

/* eslint-disable @typescript-eslint/no-explicit-any */
export default {
  // Guest user

  login(user: any) {
    return httpService.auth('POST', 'login', user)
  },

  register(user: any) {
    return httpService.auth('POST', 'register', user)
  },

  sendResetPassword(email: any) {
    return httpService.auth('POST', 'password/email', email)
  },

  // Authenticated user

  secure(user: any) {
    return httpService.auth('POST', 'secure', user)
  },

  setProfile(user: any) {
    return httpService.auth('POST', 'profile', user)
  },

  logout(user: any) {
    return httpService.auth('POST', 'logout', user)
  }
}
