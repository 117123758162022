//import { cibFacebook, cibTwitter, cibLinkedin, cibFlickr, cibTumblr, cibXing, cibGithub, cibStackoverflow, cibYoutube, cibDribbble, cibInstagram, cibPinterest, cibVk, cibYahoo, cibBehance, cibReddit, cibVimeo, cibCcMastercard, cibCcVisa, cibStripe, cibPaypal, cibGooglePay, cibCcAmex } from '@coreui/icons'
//import { cifUs, cifBr, cifIn, cifFr, cifEs, cifPl } from '@coreui/icons'
//import { cilArrowRight, cilBan, cilBasket, cilBell, cilCalculator, cilCalendar, cilCloudDownload, cilChartPie, cilCheck, cilChevronBottom, cilChevronTop, cilCheckCircle, cilCommentSquare, cilCursor, cilDrop, cilDollar, cilEnvelopeClosed, cilEnvelopeOpen, cilEuro, cilGlobeAlt, cilGrid, cilFile, cilJustifyCenter, cilLaptop, cilLayers, cilLightbulb, cilList, cilLocationPin, cilLockLocked, cilMagnifyingGlass, cilMoon, cilOptions, cilPencil, cilPeople, cilPuzzle, cilSettings, cilShieldAlt, cilSpeech, cilSpeedometer, cilStar, cilTask, cilUser, cilUserFemale, cilUserFollow, cilXCircle } from '@coreui/icons'
import { logo } from './logo'
import { cilBan, cilBell, cilList, cilEnvelopeOpen, cilChevronTop, cilCheck, cilCheckCircle, cilUser, cilLockLocked, cilArrowThickToRight, cilUserFemale, cilSettings, cilTask, cilCommentSquare, cilDollar, cilFile, cilShieldAlt, cilMagnifyingGlass, cilSpeedometer, cilPuzzle, cilOptions } from '@coreui/icons'
import { cilX, cilPlus, cilSave, cilHome, cilBank, cilHouse, cilMoney, cilMinus, cilPrint, cilCircle, cilLayers, cilReload, cilFilter, cilFilterX, cilCheckAlt, cilBuilding, cilListRich, cilTerminal, cilLowVision, cilCalculator, cilViewModule, cilPlaylistAdd, cilDescription, cilCloudUpload, cilSpreadsheet, cilCloudDownload, cilChevronBottom, cilEnvelopeClosed, cilEnvelopeLetter, cilChevronDoubleUp, cilChevronDoubleDown, cilApplicationsSettings } from '@coreui/icons'
import { cibGmail } from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  { logo },
  {
    cilBan,
    cilBell,
    cilList,
    cilEnvelopeOpen,
    cilChevronTop,
    cilCheck,
    cilCheckCircle,
    cilUser,
    cilLockLocked,
    cilArrowThickToRight,
    cilUserFemale,
    cilSettings,
    cilTask,
    cilCommentSquare,
    cilDollar,
    cilFile,
    cilShieldAlt,
    cilMagnifyingGlass,
    cilSpeedometer,
    cilPuzzle,
    cilOptions
  },
  {
    cilX,
    cilPlus,
    cilSave,
    cilHome,
    cilBank,
    cilHouse,
    cilMoney,
    cilMinus,
    cilPrint,
    cilCircle,
    cilLayers,
    cilReload,
    cilFilter,
    cilFilterX,
    cilCheckAlt,
    cilBuilding,
    cilListRich,
    cilTerminal,
    cilLowVision,
    cilCalculator,
    cilViewModule,
    cilPlaylistAdd,
    cilDescription,
    cilCloudUpload,
    cilSpreadsheet,
    cilCloudDownload,
    cilChevronBottom,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilChevronDoubleUp,
    cilChevronDoubleDown,
    cilApplicationsSettings
  },
  {
    cibGmail
  }
  /*{
    cilArrowRight,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMoon,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
  },
  {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex
  }*/
)
