/* eslint-disable @typescript-eslint/no-explicit-any */
export const reports = [
  {
    path: 'building_balance',
    name: 'BuildingBalance',
    component: () => import('@/app/views/admin/reports/BuildingBalance.vue'),
    meta: { requiresAdmin: true, label: 'Building balance' }
  },
  {
    path: 'building_balance_detailed',
    name: 'BuildingBalanceDetailed',
    component: () => import('@/app/views/admin/reports/BuildingBalanceDetailed.vue'),
    meta: { requiresAdmin: true, label: 'Building balance detailed' }
  },
  {
    path: 'building_balance_detailed_summary',
    name: 'BuildingBalanceDetailedSummary',
    component: () => import('@/app/views/admin/reports/BuildingBalanceDetailed.vue'),
    meta: { requiresAdmin: true, label: 'Building balance detailed summary', type: 'summary' }
  },
  {
    path: 'building_balance_by_concept',
    name: 'BuildingBalanceByConcept',
    component: () => import('@/app/views/admin/reports/BuildingBalanceByConcept.vue'),
    meta: { requiresAdmin: true, label: 'Building balance by concept' }
  },
  {
    path: 'building_payment_spill',
    name: 'BuildingPaymentSpill',
    component: () => import('@/app/views/admin/reports/BuildingPaymentSpill.vue'),
    meta: { requiresAdmin: true, label: 'Building payment spill' }
  },
  {
    path: 'invoicing_cube',
    name: 'InvoicingCube',
    component: () => import('@/app/views/admin/reports/InvoicingCube.vue'),
    meta: { requiresAdmin: true, label: 'Invoicing cube' }
  }
]
