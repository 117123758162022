/* eslint-disable @typescript-eslint/no-explicit-any */
export const common_area_reservations = {
  path: 'common_area_reservations',
  meta: { label: 'Common area reservations' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/tools/common_area_reservations/CommonAreaReservations.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateCommonAreaReservation',
      meta: { label: 'Create common area reservation', requiresAdmin: true },
      component: () => import('@/app/views/admin/tools/common_area_reservations/CommonAreaReservationForm.vue')
    },
    {
      path: ':id',
      name: 'CommonAreaReservation',
      meta: { label: 'Common area  reservationdetails', requiresAdmin: true },
      component: () => import('@/app/views/admin/tools/common_area_reservations/CommonAreaReservation.vue')
    },
    {
      path: ':id/edit',
      name: 'EditCommonAreaReservation',
      meta: { label: 'Edit common area reservation', requiresAdmin: true },
      component: () => import('@/app/views/admin/tools/common_area_reservations/CommonAreaReservationForm.vue')
    }
  ]
}
