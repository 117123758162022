/* eslint-disable @typescript-eslint/no-explicit-any */
export const receipts = {
  path: 'receipts',
  meta: { label: 'Receipts' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/invoicing/receipts/Receipts.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateReceipt',
      meta: { label: 'Create receipt', requiresAdmin: true },
      component: () => import('@/app/views/admin/invoicing/receipts/ReceiptForm.vue')
    },
    {
      path: ':id',
      name: 'Receipt',
      meta: { label: 'Receipt details', requiresAdmin: true },
      component: () => import('@/app/views/admin/invoicing/receipts/Receipt.vue')
    },
    {
      path: ':id/edit',
      name: 'EditReceipt',
      meta: { label: 'Edit receipt', requiresAdmin: true },
      component: () => import('@/app/views/admin/invoicing/receipts/ReceiptForm.vue')
    }
  ]
}
