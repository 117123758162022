/* eslint-disable @typescript-eslint/no-explicit-any */
export const managers = {
  path: 'managers',
  meta: { label: 'Managers' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/managers/Managers.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateManager',
      meta: { label: 'Create manager', requiresAdmin: true },
      //component: () => import('@/app/views/admin/managers/CreateManager.vue')
      component: () => import('@/app/views/admin/managers/ManagerForm.vue')
    },
    {
      path: ':id',
      name: 'Manager',
      meta: { label: 'Manager details', requiresAdmin: true },
      component: () => import('@/app/views/admin/managers/Manager.vue')
    },
    {
      path: ':id/edit',
      name: 'EditManager',
      meta: { label: 'Edit manager', requiresAdmin: true },
      //component: () => import('@/app/views/admin/managers/EditManager.vue')
      component: () => import('@/app/views/admin/managers/ManagerForm.vue')
    }
  ]
}
