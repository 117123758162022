/* eslint-disable @typescript-eslint/no-explicit-any */
export const invoices = {
  path: 'invoices',
  meta: { label: 'Invoices' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/invoicing/invoices/Invoices.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateInvoice',
      meta: { label: 'Create invoice', requiresAdmin: true },
      component: () => import('@/app/views/admin/invoicing/invoices/InvoiceForm.vue')
    },
    {
      path: ':id',
      name: 'Invoice',
      meta: { label: 'Invoice details', requiresAdmin: true },
      component: () => import('@/app/views/admin/invoicing/invoices/Invoice.vue')
    },
    {
      path: ':id/edit',
      name: 'EditInvoice',
      meta: { label: 'Edit invoice', requiresAdmin: true },
      component: () => import('@/app/views/admin/invoicing/invoices/InvoiceForm.vue')
    }
  ]
}
