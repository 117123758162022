/* eslint-disable @typescript-eslint/no-explicit-any */
export const config = {
  path: 'config',
  meta: { label: 'Gmail config' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      name: 'EditGmailConfig',
      meta: { label: 'Edit gmail config', requiresAdmin: true },
      component: () => import('@/app/views/super/gmail/GMailConfigForm.vue')
    }
  ]
}
