/* eslint-disable @typescript-eslint/no-explicit-any */
export const shares = {
  path: 'shares',
  meta: { label: 'Shares' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/property/shares/Shares.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: 'create',
      name: 'CreateShare',
      meta: { label: 'Create share', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/shares/ShareForm.vue')
    },
    {
      path: ':id',
      name: 'Share',
      meta: { label: 'Share details', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/shares/Share.vue')
    },
    {
      path: ':id/edit',
      name: 'EditShare',
      meta: { label: 'Edit share', requiresAdmin: true },
      component: () => import('@/app/views/admin/property/shares/ShareForm.vue')
    }
  ]
}
