/* eslint-disable @typescript-eslint/no-explicit-any */
export const data_import = {
  path: 'data_import',
  meta: { label: 'Data import' },
  component: {
    render: (c: any) => c('router-view')
  },
  children: [
    {
      path: '',
      component: () => import('@/app/views/admin/data_import/DataImport.vue'),
      meta: { requiresAdmin: true, type: 'property' }
    }
  ]
}
